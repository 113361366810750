<script setup>
import { JSlider } from "media-flow-ui"
import { toRefs, ref } from "vue"
import { BannerWrapperModel } from "~/models/layout/components/banner/BannerStyle"
import { formatIconStyle } from "~/helpers/icon/icon"
import { CAROUSEL_INDICATORS } from "@/constants/bannerCrousel/bannerIndicators"

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const { data } = toRefs(props)
const slides = data.value.props.items
const autoSwipeInterval = data.value.props.autoswipe

const indicatorType = data.value.props.indicators

const showArrows =
  indicatorType === CAROUSEL_INDICATORS.ARROWS_DOTS ||
  indicatorType === CAROUSEL_INDICATORS.AROWS
const showDots =
  indicatorType === CAROUSEL_INDICATORS.ARROWS_DOTS ||
  indicatorType === CAROUSEL_INDICATORS.DOTS

const bannerStyle = computed(() => {
  const {
    buttonStyle,
    descriptionStyle,
    imageStyle,
    metricsStyle,
    padding,
    titleStyle,
    verticalPosition,
    contentAlignment,
    horizontalPosition,
    borderProperties,
  } = data.value.style

  return {
    buttonStyle,
    descriptionStyle,
    imageStyle,
    metricsStyle,
    padding,
    titleStyle,
    verticalPosition,
    contentAlignment,
    horizontalPosition,
    borderProperties
  }
})

const style = computed(() => {
  const { width, height, alignment, aspectRatio } = data.value.style
  const s = new BannerWrapperModel()
  s.fromQuery({ width, height, alignment, aspectRatio })

  return s
})

const iconsStyle = formatIconStyle(data.value.style.iconsStyle)

const hasOverlay = bannerStyle.value?.imageStyle?.blur > 0 ? true : false

//text-right
</script>

<template>
  <div class="slider-wrapper overflow-hidden" :style="style">
    <JSlider
      v-if="slides"
      :slides="slides"
      :autoSlide="true"
      :scrollInterval="autoSwipeInterval"
      :activeSlide="0"
      :animationTime="'1s'"
      :showArrows="showArrows"
      :showDots="showDots"
      :icons="iconsStyle"
    >
      <template #slide="data">
        <Banner
          :data="{ props: data.slide, style: bannerStyle }"
          :has-overlay="hasOverlay"
        />
      </template>
    </JSlider>
  </div>
</template>
